import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Carousel } from "react-responsive-carousel";
import TinySlider from "tiny-slider-react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import homeOne from "../../assets/home-one.svg";
import homeTwo from "../../assets/home-two.svg";
import homeThree from "../../assets/home-three.svg";
import lightbulb from "../../assets/lightbulb.svg";
import drawerIcon from "../../assets/drawer.svg";
import gridIcon from "../../assets/grid.svg";
import aboutIcon from "../../assets/about-icon.jpg";
import televisionIcon from "../../assets/television.svg";
import arrowIcon from "../../assets/arrow.svg";
import clockIcon from "../../assets/clock.svg";
import checkGear from "../../assets/check-gear.svg";
import handsIcon from "../../assets/hands.svg";
import televsionSmallIcon from "../../assets/television-small.svg";
import arrowSmallIcon from "../../assets/arrow-small.svg";
import clockSmallIcon from "../../assets/clock-small.svg";
import checkGearSmallIcon from "../../assets/check-gear-small.svg";
import handsSmallIcon from "../../assets/hands-small.svg";
import iphoneIcon from "../../assets/iPhone-13-Pro.svg";
import iphoneOneIcon from "../../assets/iphone-icon-one.svg";
import iphoneTwoIcon from "../../assets/iphone-icon-two.svg";
import iphoneThreeIcon from "../../assets/iphone-icon-three.svg";
import iphoneFourIcon from "../../assets/iphone-icon-four.svg";
import iphoneFiveIcon from "../../assets/iphone-icon-five.svg";
import iphoneSixIcon from "../../assets/iphone-icon-six.svg";
import iphoneIconSmall from "../../assets/iphone-small.svg";
import accessLogo from "../../assets/Access-Bank-PLC-Logo.svg";
import airtelLogo from "../../assets/Airtel-Nigeria-Logo.svg";
import deonLogo from "../../assets/Tom-Olumoya-Logo.svg";
import interkelLogo from "../../assets/interkel.svg";
import totalLogo from "../../assets/totalenergies.svg";
import upsLogo from "../../assets/Up-ng-Logo.svg";
import edoLogo from "../../assets/edo-logo.svg";
import ihsLogo from "../../assets/ihs-logo.svg";
import pattern from "../../assets/pattern.svg";
import thunderbolt from "../../assets/thunderbolt.svg";
import databaseIcon from "../../assets/database-icon.svg";

const imageContainerStyles = {
	position: "relative",
};

const settings = {
	lazyload: true,
	nav: false,
	mouseDrag: true,
	autoplay: true,
	controls: false,
	loop: true,
	// items: 1,
	gutter: 5,
	autoWidth: false,
	autoHeight: false,
	autoplayButtonOutput: false,
	responsive: {
		481: {
			items: 2,
		},
		769: {
			items: 3,
		},
		900: {
			items: 4,
		},
	},
};

const Home = () => {
	const [fullName, setFullname] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("submitted");
	};

	return (
		<Box id="home">
			<Header />
			<Box component="main" sx={{ flexGrow: 1, p: 0 }}>
				<Box sx={imageContainerStyles}>
					<Carousel
						infiniteLoop
						showArrows={false}
						showThumbs={false}
						autoPlay={true}
						interval={5000}
						showStatus={false}
					>
						<Box>
							<img
								src={homeOne}
								height="100%"
								width="100%"
								alt="ict revolution"
							/>
						</Box>
						<Box>
							<img
								src={homeTwo}
								height="100%"
								width="100%"
								alt="ict revolution"
							/>
						</Box>
						<Box>
							<img
								src={homeThree}
								height="100%"
								width="100%"
								alt="ict revolution"
							/>
						</Box>
					</Carousel>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							color: "#fff",
							width: "100%",
							textAlign: "center",
						}}
					>
						<Typography
							variant="h1"
							sx={{
								fontSize: {
									md: "4.5rem",
									sm: "2.5rem",
									xs: "1.3rem",
								},
							}}
							gutterBottom
						>
							Accelerating the ICT revolution
						</Typography>
						<Typography
							sx={{
								fontSize: {
									md: "4.5rem",
									sm: "2.5rem",
									xs: "1.4rem",
								},
							}}
							variant="h1"
						>
							for business transformation
						</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						textAlign: "center",
						color: "#303030",
						padding: {
							md: "4rem",
							xs: "2rem",
						},
					}}
				>
					<Typography
						variant="h3"
						sx={{
							fontSize: {
								md: "3rem",
								sm: "2rem",
								xs: "1.3rem",
							},
						}}
						gutterBottom
						component="div"
					>
						We combine deep understanding
					</Typography>
					<Typography
						variant="h3"
						sx={{
							fontSize: {
								md: "3rem",
								sm: "2rem",
								xs: "1.3rem",
							},
						}}
						gutterBottom
						component="div"
					>
						with tech expertise
					</Typography>
					<Typography
						sx={{
							textAlign: "center",
							color: "#696969",
						}}
						variant="caption"
						display="block"
						gutterBottom
					>
						EF Technologies combines a deep understanding of the ICT industry
						and extensive technology expertise to transform businesses.
					</Typography>
				</Box>
				<Box
					sx={{
						display: {
							xs: "none",
							lg: "flex",
						},
						flexDirection: {
							md: "row",
							xs: "column",
						},
						color: "#fff",
					}}
				>
					<Box
						sx={{
							height: {
								md: "20rem",
								xs: "15rem",
							},
							width: {
								md: "20%",
								xs: "100%",
							},
						}}
					>
						<Box
							sx={{
								backgroundColor: "#B31B34",
								height: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									marginTop: "4rem",
								}}
							>
								<Box>
									<img src={lightbulb} alt="box logo" />
								</Box>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Typography variant="h5" gutterBottom>
										Solution Design
									</Typography>
									<Typography variant="caption" display="block">
										Plan and Design better and
									</Typography>
									<Typography variant="caption" display="block">
										improved scalable network or
									</Typography>
									<Typography variant="caption" display="block">
										systems
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
					<Box
						sx={{
							height: {
								md: "20rem",
								xs: "15rem",
							},
							width: {
								md: "20%",
								xs: "100%",
							},
						}}
					>
						<Box
							sx={{
								background: "linear-gradient(180deg, #108FD9 0%, #033A5A 100%)",
								height: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									marginTop: "4rem",
								}}
							>
								<Box>
									<img src={drawerIcon} alt="box logo" />
								</Box>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Typography variant="h5" gutterBottom>
										Infrastructure Build
									</Typography>
									<Typography variant="caption" display="block">
										Delivers automated and optimized
									</Typography>
									<Typography variant="caption" display="block">
										construction management solution.
									</Typography>
									<Typography variant="caption" display="block">
										We build better networks at scale
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
					<Box
						sx={{
							height: {
								md: "20rem",
								xs: "15rem",
							},
							width: {
								md: "20%",
								xs: "100%",
							},
						}}
					>
						<Box
							sx={{
								backgroundColor: "#000",
								height: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									marginTop: "4rem",
								}}
							>
								<Box>
									<img src={gridIcon} alt="box logo" />
								</Box>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Typography variant="h5" gutterBottom>
										Managed Services
									</Typography>
									<Typography variant="caption" display="block">
										Business transformation achieved
									</Typography>
									<Typography variant="caption" display="block">
										through better service levels and
									</Typography>
									<Typography variant="caption" display="block">
										reduced risk, access to technology
									</Typography>
									<Typography variant="caption" display="block" id="company">
										innovation &#38; reduced cost
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
					<Box
						sx={{
							height: {
								md: "20rem",
								xs: "15rem",
							},
							width: {
								md: "20%",
								xs: "100%",
							},
						}}
					>
						<Box
							sx={{
								background: "linear-gradient(180deg, #108FD9 0%, #033A5A 100%)",
								height: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									marginTop: "4rem",
								}}
							>
								<Box>
									<img src={thunderbolt} alt="box logo" />
								</Box>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Typography variant="h5" gutterBottom>
										Smart energy
									</Typography>
									<Typography variant="caption" display="block">
										Smart energy consulting and service
									</Typography>
									<Typography variant="caption" display="block">
										delivery to power &amp; utilities by
									</Typography>
									<Typography variant="caption" display="block">
										leveraging digital technologies to
									</Typography>
									<Typography variant="caption" display="block">
										create VALUE for the utilities
									</Typography>
									<Typography variant="caption" display="block">
										and their customers.
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
					<Box
						sx={{
							height: {
								md: "20rem",
								xs: "15rem",
							},
							width: {
								md: "20%",
								xs: "100%",
							},
						}}
					>
						<Box
							sx={{
								backgroundColor: "#B31B34",
								height: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									marginTop: "4rem",
								}}
							>
								<Box>
									<img src={databaseIcon} alt="box logo" />
								</Box>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Typography variant="h5" gutterBottom>
										Data centres
									</Typography>
									<Typography variant="caption" display="block">
										IT infrastructure deployment that
									</Typography>
									<Typography variant="caption" display="block">
										supports eveloving equipments
									</Typography>
									<Typography variant="caption" display="block">
										standards and technology requirement
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>

				{/* md to lg screen */}
				<Box
					sx={{
						color: "#fff",
						display: {
							xs: "block",
							lg: "none",
							xl: "none",
						},
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: {
								md: "row",
								xs: "column",
							},
						}}
					>
						<Box
							sx={{
								height: {
									md: "20rem",
									xs: "15rem",
								},
								width: {
									md: "33.33%",
									xs: "100%",
								},
							}}
						>
							<Box
								sx={{
									backgroundColor: "#B31B34",
									height: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Box
									sx={{
										display: "flex",
									}}
								>
									<Box
										sx={{
											alignSelf: "flex-start",
											marginRight: "1rem",
										}}
									>
										<img src={lightbulb} alt="box logo" />
									</Box>
									<Box>
										<Typography variant="h5" gutterBottom>
											Solution Design
										</Typography>
										<Typography variant="caption" display="block">
											Plan and Design better and
										</Typography>
										<Typography variant="caption" display="block">
											improved scalable network or
										</Typography>
										<Typography variant="caption" display="block">
											systems
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
						<Box
							sx={{
								height: {
									md: "20rem",
									xs: "15rem",
								},
								width: {
									md: "33.33%",
									xs: "100%",
								},
							}}
						>
							<Box
								sx={{
									background:
										"linear-gradient(180deg, #108FD9 0%, #033A5A 100%)",
									height: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Box
									sx={{
										display: "flex",
									}}
								>
									<Box
										sx={{
											alignSelf: "flex-start",
											marginRight: "1rem",
										}}
									>
										<img src={drawerIcon} alt="box logo" />
									</Box>
									<Box>
										<Typography variant="h5" gutterBottom>
											Infrastructure Build
										</Typography>
										<Typography variant="caption" display="block">
											Delivers automated and optimized
										</Typography>
										<Typography variant="caption" display="block">
											construction management solution.
										</Typography>
										<Typography variant="caption" display="block">
											We build better networks at scale
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
						<Box
							sx={{
								height: {
									md: "20rem",
									xs: "15rem",
								},
								width: {
									md: "33.33%",
									xs: "100%",
								},
							}}
						>
							<Box
								sx={{
									backgroundColor: "#000",
									height: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Box
									sx={{
										display: "flex",
									}}
								>
									<Box
										sx={{
											alignSelf: "flex-start",
											marginRight: "1rem",
										}}
									>
										<img src={gridIcon} alt="box logo" />
									</Box>
									<Box>
										<Typography variant="h5" gutterBottom>
											Managed Services
										</Typography>
										<Typography variant="caption" display="block">
											Business transformation achieved
										</Typography>
										<Typography variant="caption" display="block">
											through better service levels and
										</Typography>
										<Typography variant="caption" display="block">
											reduced risk, access to technology
										</Typography>
										<Typography variant="caption" display="block" id="company">
											innovation &#38; reduced cost
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
					{/* bottom */}
					<Box
						sx={{
							display: "flex",
							flexDirection: {
								xs: "column",
								md: "row",
							},
							color: "#fff",
						}}
					>
						<Box
							sx={{
								height: {
									md: "20rem",
									xs: "15rem",
								},
								width: {
									md: "50%",
									xs: "100%",
								},
							}}
						>
							<Box
								sx={{
									background:
										"linear-gradient(180deg, #108FD9 0%, #033A5A 100%)",
									height: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Box
									sx={{
										display: "flex",
									}}
								>
									<Box
										sx={{
											alignSelf: "flex-start",
											marginRight: "1rem",
										}}
									>
										<img src={thunderbolt} alt="box logo" />
									</Box>
									<Box>
										<Typography variant="h5" gutterBottom>
											Smart energy
										</Typography>
										<Typography variant="caption" display="block">
											Smart energy consulting and service
										</Typography>
										<Typography variant="caption" display="block">
											delivery to power &amp; utilities by
										</Typography>
										<Typography variant="caption" display="block">
											leveraging digital technologies to
										</Typography>
										<Typography variant="caption" display="block">
											create VALUE for the utilities
										</Typography>
										<Typography variant="caption" display="block">
											and their customers.
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
						<Box
							sx={{
								height: {
									md: "20rem",
									xs: "15rem",
								},
								width: {
									md: "50%",
									xs: "100%",
								},
							}}
						>
							<Box
								sx={{
									backgroundColor: "#B31B34",
									height: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Box
									sx={{
										display: "flex",
									}}
								>
									<Box
										sx={{
											alignSelf: "flex-start",
											marginRight: "1rem",
										}}
									>
										<img src={databaseIcon} alt="box logo" />
									</Box>
									<Box>
										<Typography variant="h5" gutterBottom>
											Data centres
										</Typography>
										<Typography variant="caption" display="block">
											IT infrastructure deployment that
										</Typography>
										<Typography variant="caption" display="block">
											supports eveloving equipments
										</Typography>
										<Typography variant="caption" display="block">
											standards and technology requirement
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						background: "linear-gradient(180deg, #108FD9 0%, #033A5A 100%)",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						color: "#fff",
						padding: {
							md: "3rem 3rem",
							xs: "2rem",
						},
					}}
				>
					<Typography
						variant="h3"
						sx={{
							fontSize: {
								md: "3rem",
								sm: "2rem",
								xs: "1.3rem",
							},
							textAlign: "center",
						}}
						gutterBottom
						component="div"
					>
						Our Company
					</Typography>
					<Typography
						variant="subtitle1"
						sx={{
							marginBottom: "2rem",
							textAlign: "center",
						}}
						gutterBottom
						component="div"
					>
						Who we are, what we do and how we do it
					</Typography>
					<Grid container sx={{ marginBottom: "2rem" }}>
						<Grid
							item
							md={5}
							xs={12}
							// sx={{ backgroundColor: "#054D76" }}
						>
							<Box
								sx={{
									height: {
										md: "100%",
										xs: "20rem",
									},
									backgroundImage: `url(${aboutIcon})`,
									backgroundSize: "cover",
									backgroundRepeat: "no-repeat",
									backgroundPosition: "center",
									borderRadius: {
										md: "2rem 0 0 2rem",
										xs: "1rem 1rem 0 0",
									},
								}}
							/>
						</Grid>
						<Grid
							item
							md={7}
							xs={12}
							sx={{
								padding: {
									md: "5rem",
									xs: "1rem",
								},
								backgroundColor: "#054D76",
								borderRadius: {
									md: "0 2rem 2rem 0",
									xs: "0 0 1rem 1rem",
								},
								backgroundImage: `url(${pattern})`,
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "center",
							}}
						>
							<Typography
								variant="subtitle1"
								sx={{
									marginBottom: "1.5rem",
								}}
								gutterBottom
								component="div"
							>
								At EF technologies, we set out to bring life and real
								experiences to innovative and enduring solutions by aligning
								proficiency and best practice structures.
							</Typography>
							<Typography
								variant="subtitle1"
								sx={{
									marginBottom: "1.5rem",
								}}
								gutterBottom
								component="div"
							>
								By using a truely mutual approach, we&#39;ll help organizations
								sustain performance and internalize best practices by
								transferring knowledge throughout the delivery life cycle.
							</Typography>
							<Typography variant="subtitle1" gutterBottom component="div">
								By this approach we will deliver measurable Return on Investment
								(ROI), guarantee that scope and deliverables are managed within
								expectations, and most importantly, client&#39;s issues remain
								solved.
							</Typography>
						</Grid>
					</Grid>
					<Box
						sx={{
							padding: {
								md: "0 4rem",
								xs: "0 1rem",
							},
						}}
					>
						<Typography
							variant="h3"
							sx={{
								fontSize: {
									md: "3rem",
									sm: "2rem",
									xs: "1.3rem",
								},
								textAlign: "center",
								marginBottom: "2rem",
							}}
							gutterBottom
							component="div"
						>
							How we do what we do
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								marginBottom: "2rem",
								textAlign: "center",
								fontSize: {
									md: "1rem",
									xs: "0.8rem",
								},
							}}
							gutterBottom
							component="div"
						>
							EF Technologies combines a deep understanding of the ICT industry
							and extensive technology expertise to transform businesses.
						</Typography>
					</Box>
					<Box
						sx={{
							width: "100%",
						}}
					>
						<Grid
							container
							justifyContent="space-between"
							sx={{
								display: {
									md: "flex",
									xs: "none",
								},
								height: "17.6rem",
							}}
						>
							<Grid
								item
								md={2}
								sx={{
									whiteSpace: "nowrap",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Box>
									<Typography
										variant="caption"
										display="block"
										sx={{
											fontSize: "0.8rem",
										}}
									>
										Complete ICT Solutions for
									</Typography>
									<Typography
										variant="caption"
										display="block"
										sx={{ fontSize: "0.7rem" }}
									>
										Business Competitiveness
									</Typography>
								</Box>
								<Box>
									<img src={televisionIcon} alt="what we do" />
								</Box>
							</Grid>
							<Grid
								item
								md={2}
								sx={{
									whiteSpace: "nowrap",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-end",
										height: "100%",
									}}
								>
									<Box>
										<img src={arrowIcon} alt="what we do" />
									</Box>
									<Box>
										<Typography
											variant="caption"
											display="block"
											sx={{
												fontSize: "0.8rem",
											}}
										>
											Tailored &#38; Targeted Solutions.
										</Typography>
										<Typography
											variant="caption"
											display="block"
											sx={{
												fontSize: "0.8rem",
											}}
										>
											High Productivity.
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid
								item
								md={2}
								sx={{
									whiteSpace: "nowrap",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Box>
									<Typography
										variant="caption"
										display="block"
										sx={{
											fontSize: "0.8rem",
										}}
									>
										Identify underlying problems.
									</Typography>
									<Typography
										variant="caption"
										display="block"
										sx={{
											fontSize: "0.8rem",
										}}
									>
										Improve bottom-line performance
									</Typography>
								</Box>
								<Box>
									<img src={clockIcon} alt="what we do" />
								</Box>
							</Grid>
							<Grid
								item
								md={2}
								sx={{
									whiteSpace: "nowrap",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-end",
										height: "100%",
									}}
								>
									<Box>
										<img src={checkGear} alt="what we do" />
									</Box>
									<Box>
										<Typography
											variant="caption"
											display="block"
											sx={{
												fontSize: "0.8rem",
											}}
										>
											Delivering Successful
										</Typography>
										<Typography
											variant="caption"
											display="block"
											sx={{
												fontSize: "0.8rem",
											}}
										>
											projects all the time
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid
								item
								md={2}
								sx={{
									whiteSpace: "nowrap",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Box>
									<Typography
										variant="caption"
										display="block"
										sx={{
											fontSize: "0.8rem",
										}}
									>
										Specialized services for specific, all
									</Typography>
									<Typography
										variant="caption"
										display="block"
										sx={{
											fontSize: "0.8rem",
										}}
									>
										network and business challenges
									</Typography>
								</Box>
								<Box>
									<img src={handsIcon} alt="what we do" />
								</Box>
							</Grid>
						</Grid>
					</Box>

					{/* small screen what we do */}
					<Box
						sx={{
							display: {
								md: "none",
								xs: "block",
							},
						}}
					>
						<Box
							sx={{
								display: "flex",
								marginBottom: "2rem",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									marginRight: {
										xs: "1rem",
										sm: "2rem",
									},
								}}
							>
								<img src={televsionSmallIcon} alt="what we do" />
							</Box>
							<Box>
								<Typography
									variant="caption"
									display="block"
									sx={{
										fontSize: "0.8rem",
									}}
								>
									Complete ICT Solutions for
								</Typography>
								<Typography
									variant="caption"
									display="block"
									sx={{ fontSize: "0.7rem" }}
								>
									Business Competitiveness
								</Typography>
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								marginBottom: "2rem",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									marginRight: {
										xs: "1rem",
										sm: "2rem",
									},
								}}
							>
								<Typography
									variant="caption"
									display="block"
									sx={{
										fontSize: "0.8rem",
									}}
								>
									Tailored &#38; Targeted Solutions. High Productivity.
								</Typography>
								<Typography
									variant="caption"
									display="block"
									sx={{
										fontSize: "0.8rem",
									}}
								>
									Productivity.
								</Typography>
							</Box>
							<Box>
								<img src={arrowSmallIcon} alt="what we do" />
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								marginBottom: "2rem",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									marginRight: {
										xs: "1rem",
										sm: "2rem",
									},
								}}
							>
								<img src={clockSmallIcon} alt="what we do" />
							</Box>
							<Box>
								<Typography
									variant="caption"
									display="block"
									sx={{
										fontSize: "0.7rem",
									}}
								>
									Identify underlying problems.
								</Typography>
								<Typography
									variant="caption"
									display="block"
									sx={{
										fontSize: "0.7rem",
									}}
								>
									Improve bottom-line performance
								</Typography>
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								marginBottom: "2rem",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									marginRight: {
										xs: "1rem",
										sm: "2rem",
									},
								}}
							>
								<Typography
									variant="caption"
									display="block"
									sx={{
										fontSize: "0.8rem",
									}}
								>
									Tailored &#38; Targeted Solutions. High Productivity.
								</Typography>
								<Typography
									variant="caption"
									display="block"
									sx={{
										fontSize: "0.8rem",
									}}
								>
									Productivity.
								</Typography>
							</Box>
							<Box>
								<img src={checkGearSmallIcon} alt="what we do" />
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								marginBottom: "2rem",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									marginRight: {
										xs: "1rem",
										sm: "2rem",
									},
								}}
							>
								<img src={handsSmallIcon} alt="what we do" />
							</Box>
							<Box>
								<Typography
									variant="caption"
									display="block"
									sx={{
										fontSize: "0.7rem",
									}}
								>
									Identify underlying problems.
								</Typography>
								<Typography
									variant="caption"
									display="block"
									sx={{
										fontSize: "0.7rem",
									}}
								>
									Improve bottom-line performance
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box sx={{ backgroundColor: "#F8F8F8" }}>
					<Box
						sx={{
							padding: {
								md: "3rem",
								xs: "1rem",
							},
						}}
						id="services"
					>
						<Typography
							variant="h3"
							sx={{
								fontSize: {
									md: "3rem",
									sm: "2rem",
									xs: "1.3rem",
								},
								textAlign: "center",
								marginBottom: "2rem",
							}}
							gutterBottom
							component="div"
						>
							Our Services
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								marginBottom: "2rem",
								textAlign: "center",
								fontSize: {
									md: "1rem",
									xs: "0.8rem",
								},
								color: "#696969",
							}}
							gutterBottom
							component="div"
						>
							EF Technologies offers itself for services rollout and deployment
							in the business areas listed below among others. We bring our
							expertise and professionalism to bear on any job while ensuring
							that our core values of Integrity, Excellence and Respect guide
							our work to deliver value to your organization
						</Typography>
					</Box>
					<Box
						sx={{
							display: {
								md: "flex",
								xs: "none",
							},
						}}
					>
						<Grid container>
							<Grid
								md={4}
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									marginRight: {
										lg: "-5rem",
										md: "0rem",
									},
									color: "#696969",
								}}
								item
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
									}}
								>
									<Box
										sx={{
											display: "flex",
											marginBottom: "2rem",
											alignItems: "center",
											justifyContent: "flex-end",
										}}
									>
										<Box
											sx={{
												marginRight: {
													xs: "1rem",
													sm: "2rem",
												},
											}}
										>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: {
														md: "1rem",
														xs: "0.8rem",
													},
													color: "#696969",
												}}
											>
												Network Plan, Design &#38;
											</Typography>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: {
														md: "1rem",
														xs: "0.8rem",
													},
													color: "#696969",
												}}
											>
												Construction
											</Typography>
										</Box>
										<Box>
											<img src={iphoneOneIcon} alt="what we do" />
										</Box>
									</Box>
									<Box
										sx={{
											display: "flex",
											marginBottom: "2rem",
											alignItems: "center",
											justifyContent: "flex-end",
										}}
									>
										<Box
											sx={{
												marginRight: {
													xs: "1rem",
													sm: "2rem",
												},
											}}
										>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: {
														md: "1rem",
														xs: "0.8rem",
													},
													color: "#696969",
												}}
											>
												Network Management System &#38;
											</Typography>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: {
														md: "1rem",
														xs: "0.8rem",
													},
													color: "#696969",
												}}
											>
												Operation Support System
											</Typography>
										</Box>
										<Box>
											<img src={iphoneTwoIcon} alt="what we do" />
										</Box>
									</Box>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "flex-end",
										}}
									>
										<Box
											sx={{
												marginRight: {
													xs: "1rem",
													sm: "2rem",
												},
											}}
										>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: {
														md: "1rem",
														xs: "0.8rem",
													},
													color: "#696969",
												}}
											>
												Data Centre Solutions
											</Typography>
										</Box>
										<Box>
											<img src={iphoneThreeIcon} alt="what we do" />
										</Box>
									</Box>
								</Box>
							</Grid>
							<Grid md={4} item>
								<img src={iphoneIcon} alt="" height="100%" width="100%" />
							</Grid>
							<Grid
								md={4}
								sx={{
									color: "#696969",
									display: "flex",
								}}
								item
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										marginLeft: "2rem",
										justifyContent: "center",
									}}
								>
									<Box
										sx={{
											display: "flex",
											marginBottom: "2rem",
											alignItems: "center",
										}}
									>
										<Box
											sx={{
												marginRight: {
													xs: "1rem",
													sm: "2rem",
												},
											}}
										>
											<img src={iphoneFourIcon} alt="what we do" />
										</Box>
										<Box>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: {
														md: "1rem",
														xs: "0.8rem",
													},
													color: "#696969",
												}}
											>
												Managed Network &#38; IT
											</Typography>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: {
														md: "1rem",
														xs: "0.8rem",
													},
													color: "#696969",
												}}
											>
												Services
											</Typography>
										</Box>
									</Box>
									<Box
										sx={{
											display: "flex",
											marginBottom: "2rem",
											alignItems: "center",
										}}
									>
										<Box
											sx={{
												marginRight: {
													xs: "1rem",
													sm: "2rem",
												},
											}}
										>
											<img src={iphoneFiveIcon} alt="what we do" />
										</Box>
										<Box>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: {
														md: "1rem",
														xs: "0.8rem",
													},
													color: "#696969",
												}}
											>
												Infrastructure Building &#38;
											</Typography>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: {
														md: "1rem",
														xs: "0.8rem",
													},
													color: "#696969",
												}}
											>
												Deployment
											</Typography>
										</Box>
									</Box>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
										}}
									>
										<Box
											sx={{
												marginRight: {
													xs: "1rem",
													sm: "2rem",
												},
											}}
										>
											<img src={iphoneSixIcon} alt="what we do" />
										</Box>
										<Box>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: {
														md: "1rem",
														xs: "0.8rem",
													},
													color: "#696969",
												}}
											>
												IT Solutions &#38; Services
											</Typography>
										</Box>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Box>
					{/* small screen iphone */}
					<Box
						sx={{
							display: {
								xs: "block",
								md: "none",
							},
						}}
					>
						<Grid container>
							<Grid
								xs={7}
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									color: "#696969",
								}}
								item
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										marginRight: "1rem",
									}}
								>
									<Box
										sx={{
											display: "flex",
											marginBottom: {
												sm: "2rem",
												xs: "1rem",
											},
											alignItems: "center",
											justifyContent: "flex-end",
										}}
									>
										<Box
											sx={{
												marginRight: {
													xs: "1rem",
													sm: "2rem",
												},
											}}
										>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: "0.8rem",
													textAlign: "end",
												}}
											>
												Network Plan, Design &#38;
											</Typography>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: "0.8rem",
													textAlign: "end",
												}}
											>
												Construction
											</Typography>
										</Box>
										<Box
											sx={{
												height: "3rem",
												width: "3rem",
											}}
										>
											<img
												src={iphoneOneIcon}
												alt="what we do"
												height="100%"
												width="100%"
											/>
										</Box>
									</Box>
									<Box
										sx={{
											display: "flex",
											marginBottom: {
												sm: "2rem",
												xs: "1rem",
											},
											alignItems: "center",
											justifyContent: "flex-end",
										}}
									>
										<Box
											sx={{
												marginRight: {
													xs: "1rem",
													sm: "2rem",
												},
												textAlign: "end",
											}}
										>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: "0.8rem",
												}}
											>
												Network Management
											</Typography>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: "0.8rem",
												}}
											>
												System &#38; Operation
											</Typography>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: "0.8rem",
												}}
											>
												Support System
											</Typography>
										</Box>
										<Box
											sx={{
												height: "3rem",
												width: "3rem",
											}}
										>
											<img
												src={iphoneTwoIcon}
												alt="what we do"
												height="100%"
												width="100%"
											/>
										</Box>
									</Box>
									<Box
										sx={{
											display: "flex",
											marginBottom: {
												sm: "2rem",
												xs: "1rem",
											},
											alignItems: "center",
											justifyContent: "flex-end",
										}}
									>
										<Box
											sx={{
												marginRight: {
													xs: "1rem",
													sm: "2rem",
												},
												textAlign: "end",
											}}
										>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: "0.8rem",
												}}
											>
												Data Centre
											</Typography>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: "0.8rem",
												}}
											>
												Solutions
											</Typography>
										</Box>
										<Box
											sx={{
												height: "3rem",
												width: "3rem",
											}}
										>
											<img
												src={iphoneThreeIcon}
												alt="what we do"
												height="100%"
												width="100%"
											/>
										</Box>
									</Box>
									<Box
										sx={{
											display: "flex",
											marginBottom: {
												sm: "2rem",
												xs: "1rem",
											},
											alignItems: "center",
											justifyContent: "flex-end",
										}}
									>
										<Box
											sx={{
												marginRight: {
													xs: "1rem",
													sm: "2rem",
												},
												textAlign: "end",
											}}
										>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: "0.8rem",
												}}
											>
												Managed Network
											</Typography>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: "0.8rem",
												}}
											>
												&#38; IT Services
											</Typography>
										</Box>
										<Box
											sx={{
												height: "3rem",
												width: "3rem",
											}}
										>
											<img
												src={iphoneFourIcon}
												alt="what we do"
												height="100%"
												width="100%"
											/>
										</Box>
									</Box>
									<Box
										sx={{
											display: "flex",
											marginBottom: {
												sm: "2rem",
												xs: "1rem",
											},
											alignItems: "center",
											justifyContent: "flex-end",
										}}
									>
										<Box
											sx={{
												marginRight: {
													xs: "1rem",
													sm: "2rem",
												},
												textAlign: "end",
											}}
										>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: "0.8rem",
												}}
											>
												Infrastructure Building
											</Typography>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: "0.8rem",
												}}
											>
												&#38; Deployment
											</Typography>
										</Box>
										<Box
											sx={{
												height: "3rem",
												width: "3rem",
											}}
										>
											<img
												src={iphoneTwoIcon}
												alt="what we do"
												height="100%"
												width="100%"
											/>
										</Box>
									</Box>
									<Box
										sx={{
											display: "flex",
											marginBottom: {
												sm: "2rem",
												xs: "1rem",
											},
											alignItems: "center",
											justifyContent: "flex-end",
										}}
									>
										<Box
											sx={{
												marginRight: {
													xs: "1rem",
													sm: "2rem",
												},
												textAlign: "end",
											}}
										>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: "0.8rem",
												}}
											>
												IT Solutions &#38;
											</Typography>
											<Typography
												variant="caption"
												display="block"
												sx={{
													fontSize: "0.8rem",
												}}
											>
												Services
											</Typography>
										</Box>
										<Box
											sx={{
												height: "3rem",
												width: "3rem",
											}}
										>
											<img
												src={iphoneSixIcon}
												alt="what we do"
												height="100%"
												width="100%"
											/>
										</Box>
									</Box>
								</Box>
							</Grid>
							<Grid xs={5} item>
								<img src={iphoneIconSmall} alt="" height="100%" width="100%" />
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Box
					sx={{
						backgroundColor: "#e5e5e5",
						padding: {
							md: "3rem",
						},
					}}
				>
					<Box
						sx={{
							color: "#fff",
							background: "linear-gradient(180deg, #108FD9 0%, #033A5A 100%)",
							borderRadius: "1.875rem",
						}}
					>
						<Box>
							<Box
								sx={{
									padding: {
										md: "3rem",
										xs: "1rem",
									},
								}}
							>
								<Typography
									variant="h3"
									sx={{
										fontSize: {
											md: "3rem",
											sm: "2rem",
											xs: "1.3rem",
										},
										textAlign: "center",
										marginBottom: "2rem",
									}}
									gutterBottom
									component="div"
								>
									Our Achievements
								</Typography>
								<Typography
									variant="subtitle1"
									sx={{
										marginBottom: "2rem",
										textAlign: "center",
										fontSize: {
											md: "1rem",
											xs: "0.8rem",
										},
									}}
									gutterBottom
									component="div"
								>
									While EF Technologies is still relatively a young
									organization, the brains behind the entity has delivered
									massive projects in the past, some of which are referenced
									below;
								</Typography>
							</Box>
						</Box>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Box
								sx={{
									width: {
										lg: "65%",
										md: "80%",
										xs: "90%",
									},
								}}
							>
								<Box
									sx={{
										width: "50%",
										display: "flex",
										alignItems: "center",
										borderRight: "1px solid #077DC1",
									}}
								>
									<Box
										sx={{
											backgroundColor: "#077DC1",
											borderRadius: "30px",
											padding: {
												md: "1.3rem",
											},
											width: "80%",
											visibility: "hidden",
										}}
									>
										<Typography
											variant="subtitle1"
											sx={{
												// marginBottom: "2rem",
												textAlign: "center",
												fontSize: {
													md: "1rem",
													xs: "0.8rem",
												},
											}}
											gutterBottom
											component="div"
										>
											700+KM of metro and backbone fiber infrastructure built
											&#38; deployment
										</Typography>
									</Box>
									<Box
										sx={{
											borderBottom: "1px solid #077DC1",
											width: "20%",
											visibility: "hidden",
										}}
									/>
								</Box>
								<Box
									sx={{
										width: "50%",
										display: "flex",
										alignItems: "center",
										borderRight: "1px solid #077DC1",
									}}
								>
									<Box
										sx={{
											backgroundColor: "#077DC1",
											borderRadius: "30px",
											padding: {
												md: "1.3rem",
												xs: "1rem",
											},
											width: "80%",
										}}
									>
										<Typography
											variant="subtitle1"
											sx={{
												// marginBottom: "2rem",
												textAlign: "center",
												fontSize: {
													md: "1rem",
													xs: "0.8rem",
												},
											}}
											gutterBottom
											component="div"
										>
											700+KM of metro and backbone fiber infrastructure built
											&#38; deployment
										</Typography>
									</Box>
									<Box
										sx={{
											borderBottom: "1px solid #077DC1",
											width: "20%",
										}}
									/>
								</Box>

								<Box
									sx={{
										width: "50%",
										display: "flex",
										alignItems: "center",
										borderLeft: "1px solid #B31B34",
										marginLeft: "auto",
									}}
								>
									<Box
										sx={{
											borderBottom: "1px solid #B31B34",
											width: "20%",
										}}
									/>
									<Box
										sx={{
											backgroundColor: "#B31B34",
											borderRadius: "30px",
											padding: {
												md: "1.3rem",
												xs: "1rem",
											},
											width: "80%",
										}}
									>
										<Typography
											variant="subtitle1"
											sx={{
												// marginBottom: "2rem",
												textAlign: "center",
												fontSize: {
													md: "1rem",
													xs: "0.8rem",
												},
											}}
											gutterBottom
											component="div"
										>
											Lagos State Smart City Project (CCTV Fiber Infrastructure
											Build &#38; Deployment)
										</Typography>
									</Box>
								</Box>

								<Box
									sx={{
										width: "50%",
										display: "flex",
										alignItems: "center",
										borderRight: "1px solid #000",
									}}
								>
									<Box
										sx={{
											backgroundColor: "#000",
											borderRadius: "30px",
											padding: {
												md: "1.3rem",
												xs: "1rem",
											},
											width: "80%",
										}}
									>
										<Typography
											variant="subtitle1"
											sx={{
												// marginBottom: "2rem",
												textAlign: "center",
												fontSize: {
													md: "1rem",
													xs: "0.8rem",
												},
											}}
											gutterBottom
											component="div"
										>
											4Nos Video Wall Solutions &#38; Deployment (3 Financial
											Institution)
										</Typography>
									</Box>
									<Box
										sx={{
											borderBottom: "1px solid #000",
											width: "20%",
										}}
									/>
								</Box>

								<Box
									sx={{
										width: "50%",
										display: "flex",
										alignItems: "center",
										borderLeft: "1px solid #077DC1",
										marginLeft: "auto",
									}}
								>
									<Box
										sx={{
											borderBottom: "1px solid #077DC1",
											width: "20%",
										}}
									/>
									<Box
										sx={{
											backgroundColor: "#077DC1",
											borderRadius: "30px",
											padding: {
												md: "1.3rem",
												xs: "1rem",
											},
											width: "80%",
										}}
									>
										<Typography
											variant="subtitle1"
											sx={{
												// marginBottom: "2rem",
												textAlign: "center",
												fontSize: {
													md: "1rem",
													xs: "0.8rem",
												},
											}}
											gutterBottom
											component="div"
										>
											Enterprise ToIP Solution Design and Implementation and
											many other ICT solution designs and implementations
										</Typography>
									</Box>
								</Box>

								<Box
									sx={{
										width: "50%",
										display: "flex",
										alignItems: "center",
										borderRight: "1px solid #B31B34",
									}}
								>
									<Box
										sx={{
											backgroundColor: "#B31B34",
											borderRadius: "30px",
											padding: {
												md: "1.3rem",
												xs: "1rem",
											},
											width: "80%",
										}}
									>
										<Typography
											variant="subtitle1"
											sx={{
												// marginBottom: "2rem",
												textAlign: "center",
												fontSize: {
													md: "1rem",
													xs: "0.8rem",
												},
											}}
											gutterBottom
											component="div"
										>
											Managed Services Engagement &#8212; Enterprise &#38; FTTx
											Services
										</Typography>
									</Box>
									<Box
										sx={{
											borderBottom: "1px solid #B31B34",
											width: "20%",
										}}
									/>
								</Box>

								<Box
									sx={{
										width: "50%",
										display: "flex",
										alignItems: "center",
										borderRight: "1px solid #B31B34",
									}}
								>
									<Box
										sx={{
											backgroundColor: "#B31B34",
											borderRadius: "30px",
											padding: {
												md: "1.3rem",
											},
											width: "80%",
											visibility: "hidden",
										}}
									>
										<Typography
											variant="subtitle1"
											sx={{
												// marginBottom: "2rem",
												textAlign: "center",
												fontSize: {
													md: "1rem",
													xs: "0.8rem",
												},
											}}
											gutterBottom
											component="div"
										>
											700+KM of metro and backbone fiber infrastructure built
											&#38; deployment
										</Typography>
									</Box>
									<Box
										sx={{
											borderBottom: "1px solid #B31B34",
											width: "20%",
											visibility: "hidden",
										}}
									/>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box sx={{ backgroundColor: "#e5e5e5" }}>
					<Box
						sx={{
							padding: {
								md: "0 2rem",
								xs: "1rem",
							},
						}}
					>
						<Typography
							variant="h3"
							sx={{
								fontSize: {
									md: "3rem",
									sm: "2rem",
									xs: "1.3rem",
								},
								textAlign: "center",
								marginBottom: "2rem",
							}}
							gutterBottom
							component="div"
						>
							Our Clients
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								marginBottom: "2rem",
								textAlign: "center",
								fontSize: {
									md: "1rem",
									xs: "0.8rem",
								},
							}}
							gutterBottom
							component="div"
						>
							A few of the people that trust us
						</Typography>
					</Box>

					<TinySlider settings={settings}>
						<Box sx={{ height: "3rem", width: "3rem" }}>
							<img src={accessLogo} alt="partners" height="100%" width="100%" />
						</Box>
						<Box sx={{ height: "2.5rem", width: "2.5rem" }}>
							<img src={airtelLogo} alt="partners" height="100%" width="100%" />
						</Box>
						<Box sx={{ height: "3rem", width: "3rem" }}>
							<img src={deonLogo} alt="partners" height="100%" width="100%" />
						</Box>
						<Box sx={{ height: "3rem", width: "3rem" }}>
							<img
								src={interkelLogo}
								alt="partners"
								height="100%"
								width="100%"
							/>
						</Box>
						<Box sx={{ height: "3rem", width: "3rem" }}>
							<img src={totalLogo} alt="partners" height="100%" width="100%" />
						</Box>
						<Box sx={{ height: "3rem", width: "3rem" }}>
							<img src={upsLogo} alt="partners" height="100%" width="100%" />
						</Box>
						<Box sx={{ height: "3rem", width: "3rem" }}>
							<img src={edoLogo} alt="partners" height="100%" width="100%" />
						</Box>
						<Box sx={{ height: "3rem", width: "3rem" }}>
							<img src={ihsLogo} alt="partners" height="100%" width="100%" />
						</Box>
					</TinySlider>

					{/* <Box
						sx={{
							padding: "0 3rem",
							display: {
								md: "flex",
								xs: "none",
							},
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Box sx={{ height: "6rem", width: "6rem" }}>
							<img
								src={accessLogo}
								alt="partners"
								height="100%"
								width="100%"
							/>
						</Box>
						<Box sx={{ height: "6rem", width: "6rem" }}>
							<img
								src={airtelLogo}
								alt="partners"
								height="100%"
								width="100%"
							/>
						</Box>
						<Box sx={{ height: "8rem", width: "8rem" }}>
							<img
								src={deonLogo}
								alt="partners"
								height="100%"
								width="100%"
							/>
						</Box>
						<Box sx={{ height: "8rem", width: "8rem" }}>
							<img
								src={interkelLogo}
								alt="partners"
								height="100%"
								width="100%"
							/>
						</Box>
						<Box sx={{ height: "5rem", width: "5rem" }}>
							<img
								src={totalLogo}
								alt="partners"
								height="100%"
								width="100%"
							/>
						</Box>
						<Box sx={{ height: "6rem", width: "6rem" }}>
							<img
								src={upsLogo}
								alt="partners"
								height="100%"
								width="100%"
							/>
						</Box>
						<Box sx={{ height: "4rem", width: "4rem" }}>
							<img
								src={edoLogo}
								alt="partners"
								height="100%"
								width="100%"
							/>
						</Box>
						<Box sx={{ height: "4rem", width: "4rem" }}>
							<img
								src={ihsLogo}
								alt="partners"
								height="100%"
								width="100%"
							/>
						</Box>
					</Box> */}

					{/* <Box
						sx={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Box
							sx={{
								width: "90%",
								display: { xs: "block", md: "none" },
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<Box
									sx={{
										height: {
											sm: "6rem",
											xs: "4rem",
										},
										width: {
											sm: "6rem",
											xs: "4rem",
										},
									}}
								>
									<img
										src={accessLogo}
										alt="partners"
										height="100%"
										width="100%"
									/>
								</Box>
								<Box
									sx={{
										height: {
											sm: "6rem",
											xs: "4rem",
										},
										width: {
											sm: "6rem",
											xs: "4rem",
										},
									}}
								>
									<img
										src={airtelLogo}
										alt="partners"
										height="100%"
										width="100%"
									/>
								</Box>
								<Box
									sx={{
										height: {
											sm: "6rem",
											xs: "3rem",
										},
										width: {
											sm: "6rem",
											xs: "3rem",
										},
									}}
								>
									<img
										src={totalLogo}
										alt="partners"
										height="100%"
										width="100%"
									/>
								</Box>
								<Box
									sx={{
										height: {
											sm: "6rem",
											xs: "3rem",
										},
										width: {
											sm: "6rem",
											xs: "3rem",
										},
									}}
								>
									<img
										src={upsLogo}
										alt="partners"
										height="100%"
										width="100%"
									/>
								</Box>
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<Box
									sx={{
										height: {
											sm: "6rem",
											xs: "4rem",
										},
										width: {
											sm: "6rem",
											xs: "4rem",
										},
									}}
								>
									<img
										src={deonLogo}
										alt="partners"
										height="100%"
										width="100%"
									/>
								</Box>
								<Box
									sx={{
										height: {
											sm: "6rem",
											xs: "4rem",
										},
										width: {
											sm: "6rem",
											xs: "4rem",
										},
									}}
								>
									<img
										src={interkelLogo}
										alt="partners"
										height="100%"
										width="100%"
									/>
								</Box>
								<Box
									sx={{
										height: {
											sm: "4rem",
											xs: "3rem",
										},
										width: {
											sm: "4rem",
											xs: "3rem",
										},
									}}
								>
									<img
										src={edoLogo}
										alt="partners"
										height="100%"
										width="100%"
									/>
								</Box>
								<Box
									sx={{
										height: {
											sm: "4rem",
											xs: "3rem",
										},
										width: {
											sm: "4rem",
											xs: "3rem",
										},
									}}
								>
									<img
										src={ihsLogo}
										alt="partners"
										height="100%"
										width="100%"
									/>
								</Box>
							</Box>
						</Box>
					</Box> */}
				</Box>
				<Box
					sx={{
						backgroundColor: "#e5e5e5",
						color: "#fff",
						padding: {
							md: "3rem",
							xs: "2rem",
						},
						boxSizing: "border-box",
					}}
					id="contact"
				>
					<Box
						sx={{
							background: "linear-gradient(180deg, #108FD9 0%, #033A5A 100%)",
							borderRadius: "1.875rem",
							display: "flex",
							flexDirection: {
								md: "row",
								xs: "column",
							},
						}}
					>
						<Box
							sx={{
								padding: {
									lg: "5rem",
									md: "2rem",
									xs: "3rem 1rem 1rem 1rem",
								},
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								width: {
									md: "50%",
									// xs: "100%",
								},
							}}
						>
							<Box
								sx={{
									textAlign: {
										md: "start",
										xs: "center",
									},
								}}
							>
								<Typography
									sx={{
										fontSize: {
											md: "3rem",
											xs: "2rem",
										},
									}}
									variant="h3"
									gutterBottom
								>
									Let&#39;s discuss,
								</Typography>
								<Typography
									sx={{
										fontSize: {
											md: "3rem",
											xs: "2rem",
										},
									}}
									variant="h3"
									gutterBottom
								>
									Let&#39;s Partner,
								</Typography>
								<Typography
									sx={{
										marginBottom: "2rem",
										fontSize: {
											md: "3rem",
											xs: "2rem",
										},
									}}
									variant="h3"
									gutterBottom
								>
									Let&#39;s go
								</Typography>
								<Typography
									variant="body1"
									sx={{
										textAlign: {
											md: "start",
											xs: "center",
											maxWidth: "18rem",
										},
									}}
									gutterBottom
								>
									Get in touch with us and let&#39;s create something awesome
									together
								</Typography>
							</Box>
						</Box>
						<Box
							sx={{
								width: {
									md: "50%",
								},
								padding: {
									lg: "5rem 11rem 5rem 5rem",
									md: "2rem 6rem 2rem 2rem",
									xs: "1rem 1rem 3rem 1rem",
								},
							}}
						>
							<Box
								component="form"
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
								noValidate
								autoComplete="off"
								onSubmit={handleSubmit}
							>
								<Stack
									direction={{ xs: "column", sm: "row" }}
									spacing={{ xs: 2, sm: 2, md: 4 }}
									justifyContent="center"
									sx={{
										width: "100%",
										marginBottom: "1rem",
									}}
								>
									<TextField
										id="fullname"
										label="Full name"
										color="secondary"
										sx={{
											input: {
												color: "white",
												"&:focus": {
													color: "white",
												},
												backgroundColor: "#0A83C9",
											},
											label: {
												color: "white",
											},
											width: "100%",
										}}
										value={fullName}
										onChange={(e) => setFullname(e.target.value)}
									/>
									<TextField
										id="companyName"
										label="Company name"
										color="secondary"
										sx={{
											input: {
												color: "white",
												"&:focus": {
													color: "white",
												},
												backgroundColor: "#0A83C9",
											},
											label: {
												color: "white",
											},
											width: "100%",
										}}
										value={companyName}
										onChange={(e) => setCompanyName(e.target.value)}
									/>
								</Stack>

								<Stack
									direction={{ xs: "column", sm: "row" }}
									spacing={{ xs: 2, sm: 2, md: 4 }}
									justifyContent="center"
									sx={{
										width: "100%",
										marginBottom: "1rem",
									}}
								>
									<TextField
										id="phone"
										label="Phone no."
										color="secondary"
										sx={{
											input: {
												color: "white",
												"&:focus": {
													color: "white",
												},
												backgroundColor: "#0A83C9",
											},
											label: {
												color: "white",
											},
											width: "100%",
										}}
										value={phoneNumber}
										onChange={(e) => setPhoneNumber(e.target.value)}
									/>
									<TextField
										id="email"
										label="Email Address"
										color="secondary"
										sx={{
											input: {
												color: "white",
												"&:focus": {
													color: "white",
												},
												backgroundColor: "#0A83C9",
											},
											label: {
												color: "white",
											},
											width: "100%",
										}}
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</Stack>

								<TextField
									id="message"
									label="Your Message"
									color="secondary"
									type="textArea"
									multiline
									rows={4}
									sx={{
										backgroundColor: "#0A83C9 !important",
										marginBottom: "1rem",
										width: "100%",
										textArea: {
											color: "white",
											"&:focus": {
												color: "white",
											},
										},
										label: {
											color: "white",
										},
									}}
									value={message}
									onChange={(e) => setMessage(e.target.value)}
								/>

								<Button
									sx={{
										backgroundColor: "#B31B34",
										color: "#fff",
										width: "100%",
										padding: "0.8rem",
										"&:active, &:hover": {
											backgroundColor: "#B31B34",
											color: "#fff",
										},
									}}
									variant="contained"
									type="submit"
								>
									submit
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
			<Footer />
		</Box>
	);
};

export default Home;
