import { useState, cloneElement } from "react";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import logo from "../assets/logo.svg";

function ElevationScroll(props) {
	const { children } = props;
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
	});

	return cloneElement(children, {
		elevation: trigger ? 4 : 0,
	});
}

const Offset = styled("div")(({ theme }) => ({
	...theme.mixins.toolbar,
	marginBottom: "0.8rem",
	[theme.breakpoints.down("md")]: {
		marginBottom: "0.45rem",
	},
	[theme.breakpoints.down("sm")]: {
		marginBottom: "0.6rem",
	},
}));
const Logo = styled("img")(({ theme }) => ({
	height: "4.5rem",
	margin: "0 6rem",
	[theme.breakpoints.down("md")]: {
		height: "4.5rem",
	},
	[theme.breakpoints.down("sm")]: {
		height: "4.5rem",
		margin: "0 3rem",
	},
}));
const SmallLogo = styled("img")(({ theme }) => ({
	height: "4.5rem",
	[theme.breakpoints.down("md")]: {
		height: "4.5rem",
	},
	[theme.breakpoints.down("sm")]: {
		height: "4.5rem",
	},
}));

const handleHomeClick = (e) => {
	let home = document.getElementById("home");
	e.preventDefault();
	home &&
		home.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
};

const handleCompanyClick = (e) => {
	let company = document.getElementById("company");
	e.preventDefault();
	company &&
		company.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
};

const handleServicesClick = (e) => {
	let services = document.getElementById("services");
	e.preventDefault();
	services &&
		services.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
};

const handleContactClick = (e) => {
	let contact = document.getElementById("contact");
	e.preventDefault();
	contact &&
		contact.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
};

const pageLinks = [
	{ title: "Home", link: "#home", linkClick: handleHomeClick },
	{ title: "Our Company", link: "#company", linkClick: handleCompanyClick },
	{
		title: "Our Services",
		link: "#services",
		linkClick: handleServicesClick,
	},
	{ title: "Contact Us", link: "#contact", linkClick: handleContactClick },
];

const Header = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	const iOS =
		typeof navigator !== "undefined" &&
		/iPad|iPhone|iPod/.test(navigator.userAgent);

	const [openDrawer, setOpenDrawer] = useState(false);

	const toggleDrawer = (open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setOpenDrawer(open);
	};

	const list = () => (
		<Box
			role="presentation"
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}
		>
			<List
				sx={{
					textAlign: "center",
				}}
			>
				{pageLinks.map((item) => (
					<ListItem
						sx={{
							textAlign: "center",
							marginBottom: "1rem",
						}}
						button
						key={item.title}
						component={Link}
						href={item.link}
					>
						<ListItemText primary={item.title} />
					</ListItem>
				))}
			</List>
		</Box>
	);

	const drawer = (
		<>
			<SwipeableDrawer
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
				open={openDrawer}
				anchor="top"
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				sx={{
					"& .MuiDrawer-paper": {
						backgroundColor: "#F8F8F8",
					},
				}}
			>
				{/* <Offset /> */}
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						padding: "1rem",
					}}
				>
					<Box
						component={Link}
						href="#home"
						onClick={toggleDrawer(false)}
					>
						<SmallLogo alt="company logo" src={logo} />
					</Box>
					<IconButton
						onClick={toggleDrawer(false)}
						aria-label="close menu"
					>
						<CloseIcon sx={{ fontSize: "1.5rem" }} />
					</IconButton>
				</Box>
				{list()}
				<Button
					sx={{
						backgroundColor: "#077DC1",
						color: "white",
						borderRadius: 0,
						padding: "1rem 4rem",
						textTransform: "none",
						marginBottom: "3rem",
						width: {
							md: "70%",
						},
						alignSelf: "center",
						"&:hover": {
							backgroundColor: "#077DC1",
							color: "#FFF",
						},
					}}
					component={Link}
					href="#contact"
					onClick={toggleDrawer(false)}
				>
					Get in touch
				</Button>
			</SwipeableDrawer>
			<IconButton
				sx={{
					marginLeft: "auto",
					"&:hover": {
						backgroundColor: "transparent",
					},
				}}
				onClick={() => setOpenDrawer(!openDrawer)}
				disableRipple
			>
				<MenuIcon
					sx={{
						height: "50px",
						width: "50px",
					}}
				/>
			</IconButton>
		</>
	);

	const sections = (
		<Box
			sx={{
				flexGrow: 1,
				display: { xs: "none", md: "flex" },
				padding: "0",
				height: "100%",
			}}
		>
			{pageLinks.map((page) => (
				<Button
					key={page.title}
					sx={{
						my: 2,
						color: "#696969",
						display: "block",
						textTransform: "none",
						marginRight: "3rem",
					}}
					component={Link}
					href={page.link}
					onClick={page.linkClick}
				>
					{page.title}
				</Button>
			))}
			<Button
				sx={{
					backgroundColor: "#077DC1",
					color: "white",
					borderRadius: 0,
					marginLeft: "auto",
					paddingLeft: "4rem",
					paddingRight: "4rem",
					textTransform: "none",
					minHeight: "5rem",
					"&:hover": {
						backgroundColor: "#077DC1",
						color: "#FFF",
					},
				}}
				component={Link}
				href="#contact"
				onClick={handleContactClick}
			>
				Get in touch
			</Button>
		</Box>
	);

	return (
		<>
			<ElevationScroll>
				<AppBar
					sx={{
						// zIndex: theme.zIndex.modal + 1,
						backgroundColor: "#F8F8F8",
					}}
				>
					<Toolbar
						disableGutters
						sx={{
							color: "#696969",
						}}
					>
						<Button
							sx={{ padding: "0" }}
							component={Link}
							disableRipple
							href="#home"
							onClick={handleHomeClick}
						>
							<Logo alt="company logo" src={logo} />
						</Button>
						{matches ? drawer : sections}
					</Toolbar>
				</AppBar>
			</ElevationScroll>
			<Offset />
		</>
	);
};

export default Header;
