import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import footerImage from "../assets/logo.svg";
import footerPattern from "../assets/footer-pattern.svg";

const Foot = styled("footer")(({ theme }) => ({
	width: "100%",
	backgroundColor: "#e5e5e5",
	zIndex: 1302,
	position: "relative",
	// border: "2px solid black",
	paddingBottom: "5rem",
	backgroundImage: `url(${footerPattern})`,
	backgroundSize: "cover",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "center",
	fontFamily: "Roboto",
}));

const FooterImageContainer = styled("div")(({ theme }) => ({
	height: "5rem",
	width: "5rem",
	marginBottom: "2rem",
}));

const linkStyle = {
	textDecoration: "none",
	color: "#696969",
	fontSize: {
		md: "0.9rem",
		xs: "0.8rem",
	},
};

const Footer = () => {
	return (
		<Foot>
			<Grid container justifyContent="center" spacing={{ xs: 2, md: 0 }}>
				<Grid item xs={12} md={5}>
					<Box
						sx={{
							marginLeft: {
								md: "7rem",
								sm: "4rem",
								xs: "1rem",
							},
						}}
					>
						<Box component={Link} href="#home">
							<FooterImageContainer>
								<img
									src={footerImage}
									height="100%"
									width="100%"
									alt="ef technologoes logo"
								/>
							</FooterImageContainer>
						</Box>
						<Box sx={{ paddingLeft: "1rem" }}>
							<Typography
								sx={{
									color: "#696969",
								}}
								variant="caption"
								display="block"
							>
								EF Technologies Limited
							</Typography>
							<Typography
								sx={{
									color: "#696969",
								}}
								variant="caption"
								display="block"
							>
								7b, Femi Okunnu Estate Phase 1, Lekki, Lagos.
							</Typography>
							<Typography
								sx={{
									color: "#696969",
								}}
								variant="caption"
								display="block"
							>
								info@eft.ng
							</Typography>
							<Typography
								sx={{
									color: "#696969",
								}}
								variant="caption"
								display="block"
								gutterBottom
							>
								+234 (0) 810 071 1620
							</Typography>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} md={7}>
					<Grid container sx={{ height: "100%" }}>
						<Grid xs={4} item>
							<Grid
								container
								direction="column"
								alignItems="center"
								spacing={4}
							>
								<Grid sx={{ fontWeight: "bold" }} item>
									Company
								</Grid>
								<Grid sx={linkStyle} component={Link} href="#company" item>
									Our Company
								</Grid>
								<Grid sx={linkStyle} component={Link} href="#services" item>
									Our Services
								</Grid>
								<Grid sx={linkStyle} component={Link} href="#contact" item>
									Contact Us
								</Grid>
							</Grid>
						</Grid>
						<Grid xs={4} item>
							<Grid
								container
								direction="column"
								alignItems="center"
								spacing={4}
							>
								<Grid sx={{ fontWeight: "bold" }} item>
									Services
								</Grid>
								<Grid sx={linkStyle} item>
									Telecommunications
								</Grid>
								<Grid sx={linkStyle} item>
									Data centre solutions
								</Grid>
								<Grid sx={linkStyle} item>
									IT infrastructure
								</Grid>
							</Grid>
						</Grid>
						<Grid xs={4} item>
							<Grid
								container
								direction="column"
								alignItems="center"
								spacing={4}
							>
								<Grid sx={{ fontWeight: "bold" }} item>
									Follow us
								</Grid>
								<Grid
									sx={linkStyle}
									component={Link}
									href="https://www.linkedin.com/company/ef-technologies/about/"
									item
								>
									LinkedIn
								</Grid>
								<Grid sx={linkStyle} component={Link} href="#" item>
									Twitter
								</Grid>
								<Grid sx={linkStyle} component={Link} href="#" item>
									Instagram
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Box sx={{ marginTop: "3rem" }}>
				<Typography
					sx={{
						color: "#696969",
						textAlign: "center",
					}}
					variant="caption"
					display="block"
				>
					Copyright 2021 EFTechnologies. All rights reserved
				</Typography>
			</Box>
		</Foot>
	);
};

export default Footer;
