import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import customTheme from "./components/Theme";
import Home from "./pages/Home/Home";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function App() {
	return (
		<ThemeProvider theme={customTheme}>
			<div className="App">
				<BrowserRouter>
					<Switch>
						<Route exact path="/" component={Home} />
					</Switch>
				</BrowserRouter>
			</div>
		</ThemeProvider>
	);
}

export default App;
